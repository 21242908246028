import {getCookie, removeCookie, setCookie} from "@/helpers/cookies";
import {getConstant} from "@/helpers/global";
import { getResponseMessage, success } from '@/helpers/httpStatusCode'

const {fetchData} = require('@/helpers/featch');
const tokenName = getConstant('TOKEN_COOKIE_NAME');
const refreshTokenName = getConstant('REFRESH_TOKEN_COOKIE_NAME');
const superAdminName = getConstant('SUPER_ADMIN_COOKIE_NAME');

const day = 1440;

export const state = {
    supplierData: {},
    token: getCookie(tokenName),
    refreshToken: getCookie(refreshTokenName),
};

export const getters = {
    isLoggedIn: state => {
        return getCookie(tokenName) || state.token;
    },
    loggedIn: state => state.isLoggedIn,
    getToken: () => getCookie(tokenName),
};

export const mutations = {
    SET_REFRESH_TOKEN(state, refreshToken) {
        setCookie(refreshTokenName, refreshToken, day * 14);
        state.refreshToken = refreshToken;
    },

    SET_TOKEN(state, token) {
        setCookie(tokenName, token, day * 14);
        state.token = token;
    }
};

export const actions = {
    /* eslint-disable */
    async logout({commit, dispatch}) {
        removeCookie(tokenName);
        removeCookie(refreshTokenName);
        removeCookie(superAdminName);
        localStorage.removeItem('stayLoggedIn')
        window.location.href = "/login";
    },

    async validate({commit, dispatch}) {
        return true;
    },

    async refreshToken({commit}) {
        const refresh_token = getCookie(refreshTokenName);
        const body = JSON.stringify({refresh_token});
        const {data} = await fetchData.post("/token", body);
        const {token, refreshToken} = data;
        if (token) {
            commit("SET_TOKEN", token);
            commit("SET_REFRESH_TOKEN", refreshToken);

            return true;
        }

        return false;
    },


    async login({commit}, {email, password} = {}) {
        const body = JSON.stringify({email, password});
        const {data} = await fetchData.post("/login", body);
        const {message, token, refreshToken, code} = data
        if (token) {
            commit("SET_TOKEN", token);
            commit("SET_REFRESH_TOKEN", refreshToken);
        }

        return {
            message: getResponseMessage(code, success(code) ? "Logowanie przebiegło poprawnie" : message),
            success: success(code),
        };
    },

    /* eslint-disable */
    async resetPassword({commit}, email) {
        const body = JSON.stringify({email});
        return await fetchData.post("/ResetPassword", body);
    },

    async resetPasswordByPin({commit}, payload) {
        const body = JSON.stringify(payload);
        return await fetchData.post("/ResetPasswordByPinCode", body);
    },
};
