export const DICTIONARY = {
  TOKEN_COOKIE_NAME: "emotv-ntYmax3",
  REFRESH_TOKEN_COOKIE_NAME: "emotv-7WsHAPw1",
  SUPER_ADMIN_COOKIE_NAME: "emotv-7PagAwp",
  SHOPS:['GR', 'TUTAY'],
  OPERATION_FAILED: 'Operacja nie powiodła się...',
  OPERATION_SUCCEEDED: 'Operacja powiodła się...',
  DATA_UPDATE_MESSAGE: 'Dane/zmiany zostaną zaktualizowane w ciągu 1 minuty.',
  OLD_GR_MESSAGE: '<span>Ta aplikacja nie obsługuje wprowadzonego numeru vouchera. Wykonaj rezerwację na stronie <a href="https://go-racing.pl/system-rezerwacyjny">go-racing.pl/system-rezerwacyjny</a></span>',
  GIFTCARD_MESSAGE: "<span>Jest to giftcard/karta podarunkowa albo kod rabatowy. NIE REALIZUJ ŻADNYCH USŁUG. Odeślij Klienta do Biura Obsługi Klienta <a href='https://prezentmarzen.com'>Prezent Marzen</a>.</span>",
  EXPIRED_MESSAGE: "Voucher jest przeterminowany. NIE REALIZUJ TEGO VOUCHERA!",
  TERMINATED_MESSAGE: "Voucher został już zrealizowany. NIE REALIZUJ TEGO VOUCHERA!",
  IN_ORDER_MESSAGE: 'Voucher nieaktywny. NIE REALIZUJ TEGO VOUCHERA!',
  REALIZED_MESSAGE: 'Voucher został już zrealizowany. Pamiętaj o rozliczeniu!',
  USED_MESSAGE: 'Voucher został już rozliczony.',
  NOT_ACTIVE_MESSAGE: 'Voucher jest nieaktywny. NIE REALIZUJ TEGO VOUCHERA!',
  INVALID_STATUS_MESSAGE: 'Nieprawidłowy status vouchera.',
  VOUCHER_BLOCKED_MESSAGE: 'Voucher jest zablokowany.',
  VOUCHER_EXPIRED_MESSAGE: 'Voucher jest przeterminowany.',
  VOUCHER_TYPE_MESSAGE: 'Nie można wykonać tej akcji. Nieprawidłowy typ vouchera.',
  VOUCHER_BOX_MESSAGE: 'Nie można wykonać tej akcji. Voucher jest zestawem prezentowym.',
  VOUCHER_BELONGS_TO_ANOTHER_CONTRACTOR_MESSAGE: "<span>Voucher należy do innego Wykonawcy. Skieruj klienta do Biura Obsługi Klienta Emotivo <a href='https://prezentmarzen.com'>prezentmarzen.com</a> / <a href='https://go-racing.pl'>go-racing.pl</a>.</span>",
  NO_ATTRACTION_SELECTED_MESSAGE: "<spna>Klient nie dokonał wyboru konkretnej atrakcji spośród dostępnych w tym BOX. Może to zrobić na stronie <a href='https://moj.pm'>moj.pm</a></spna>",
  INVALID_VOUCHER_NUMBER_MESSAGE: 'Niepoprawny numer vouchera. Sprawdź numer vouchera i spróbuj ponownie.',
  UNKNOWN_SOURCE_MESSAGE: "<span>Ten voucher pochodzi z nieobsługiwanego źródła.</span>",
  DISCOUNT_CODE_MESSAGE: "<span>Wprowadzony voucher to karta podarunkowa lub kod rabatowy. NIE REALIZUJ ŻADNYCH USŁUG! Odeślij Klienta do Biura Obsługi Klienta Emotivo <a href='https://prezentmarzen.com'>prezentmarzen.com</a> / <a href='https://go-racing.pl'>go-racing.pl</a>.</span>",
  GIFTCARD: "Gift Card",
  IN_RESERVATION: "W rezerwacji",
  IN_CART: "W rezerwacji",
  IN_ORDER: "W zamówieniu",
  ACTIVE: "Aktywny",
  NOT_ACTIVE: 'Nieaktywny',
  TERMINATED: "Zrealizowany",
  USED: "Rozliczony",
  BLOCKED:  "Zablokowany",
  EXPIRED: "Przeterminowany",
  SETTLED: 'Rozliczony',
  BOX: 'Box',
  CURRENCY: 'zł',
  NOT_EXISTS:'Nie istnieje',
  SOMETHING_WENT_WRONG:'Coś poszło nie tak...',
  SEND_REPORT_FAILED_MESSAGE: 'Nie udało się wysłać raportu do działu finansów. Spróbuj ponownie lub skontaktuj się z działem finansów.',
  SEND_REPORT_SUCCESS_MESSAGE: 'Raport został wysłany do Działu Finansów Emotivo. Na jego podstawie wystaw fakturę i wyślij ją na adres mailowy finanse@emotivo.pl. Po zatwierdzeniu raportu przez Dział Finansów, faktura zostanie opłacona w terminie zgodnym z umową.',
  RAPORT_DELETED_CODE_MESSAGE: 'Raport został usunięty',
  INVALID_CREDENTIALS_CODE_MESSAGE: 'Nieprawidłowe dane uwierzytelniające',
  INVALID_TOKEN_MESSAGE: 'Nieprawidłowy token',
  TOKEN_EXPIRED_MESSAGE: 'Token wygasł',
  COMMUNICATION_ERROR_MESSAGE: 'Błąd komunikacji z serwerem. Spróbuj ponownie później.',
  OBJECT_NOT_FOUND_MESSAGE: 'Niepoprawny numer vouchera. Sprawdź numer vouchera i spróbuj ponownie.',
  INTERNAL_ERROR_MESSAGE: 'Błąd wewnętrzny',
  INTERNAL_SERVER_ERROR: 'Błąd w komunikacji z innym serwisem'
}
