module.exports = {
    appUrl: process.env.NODE_ENV === 'production' ? 'https://wykonawca.emotivo.pl' : 'https://wykonawca-dev.emotivo.pl',
    env: process.env.NODE_ENV ?? 'development',
    apiBaseUrl: process.env.VUE_APP_API_BASE_URL + '/modules/voucherreservationapp/',
    as_api: (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_AS_API : process.env.VUE_APP_AS_API_TEST) + '/api',
    lm_api: (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : process.env.VUE_APP_API_DEV_URL) + '/lm_api/supplier-api',
    super_admin: process.env.VUE_APP_API_BASE_URL + '/lm_api/ff3b4b0290663535ebb9922bbb047bd0-api',
    baseUrl: process.env.VUE_APP_FRONTEND_URL,
    serverBaseURL: process.env.NODE_ENV !== 'production' ? 'https://v1dev-prezentmarzen.emotivo.pl' : 'https://prezentmarzen.com', //process.env.VUE_APP_API_BASE_URL
    publicPath: process.env.VUE_APP_PUBLIC_PATH ?? '/',
    googleAnalyticsDefaultId: 'G-T9FX3WN62D',
    googleAnalyticsId: '',
    appVersion: process.env.VUE_APP_VERSION ?? '1.0',
    hotjar: process.env.VUE_APP_HOTJAR ?? '000000',

};
