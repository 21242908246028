<template>
  <div>
    <v-navigation-drawer
      v-if='mobile'
      v-click-outside='closeDrawer'
      class='background accent-4 pt-3'
      v-model='show'
      fixed
      temporary
    >
      <div class='d-flex align-center mobile-padding-top'>
        <v-btn class='ml-4' @click='closeDrawer' icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <img src='@/assets/emotivo_logo.svg' width='169' height='37' />
      </div>

      <v-card
        class='d-flex wrapper py-2 px-3 ma-3'
        outlined
        rounded='lg'
        dark
        color='primary'
        @click='closeDrawer'
        to='/info'
      >
        <v-avatar size='40' color='white'>
          <v-img :src='supplier.image' />
        </v-avatar>
        <div class='ml-3'>
          <div class='body-2'>{{ $helper.truncate(supplier.supplier_name) }}</div>
          <div class='caption'>info</div>
        </div>
      </v-card>
      <v-list shaped>
        <v-list-item-group multiple>
          <v-list-item
            v-for='(item, i) in links'
            :key='i'
            :target='item.target'
            :to="item.target ? null : item.path"
            :href="item.target ? item.path : ''"
            link
            small
          >
            <v-list-item-icon>
              <v-icon v-text='item.icon'></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text='item.title' />
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-divider class='my-4' />
        <div v-for='(n, index) in telephones' :key='index'>
          <v-subheader class='phone'>{{ n.department }}</v-subheader>
          <v-list-item color='primary' class='mb-1' :href='`tel:${n.phone},${n.extension}`' small link>
            <v-list-item-icon>
              <v-icon color='secondary'>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <div>{{ n.phone }} wew. {{ n.extension }}</div>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-else
      class='background accent-4'
      app
    >
      <v-card
        class='d-flex wrapper py-2 px-3 ma-3'
        rounded='lg'
        outlined
        dark
        color='primary'
        to='/info'
      >
        <v-avatar size='40' color='white'>
          <v-img :src='supplier.image' />
        </v-avatar>
        <div class='ml-3'>
          <div class='body-2'>{{ $helper.truncate(supplier.supplier_name) }}</div>
          <div class='caption'>info</div>
        </div>
      </v-card>

      <v-list>
        <v-list shaped>
          <v-list-item-group multiple>
            <v-list-item
              v-for='(item, i) in links'
              :key='i'
              :to="item.path"
              :target='item.target'
              link
            >
              <v-list-item-icon>
                <v-icon v-text='item.icon'></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text='item.title' />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-divider class='my-4' />
        <div v-for='(n, index) in telephones' :key='index'>
          <v-subheader style='height: fit-content;'>{{ n.department }}</v-subheader>
          <v-list-item color='primary' :href="`tel:${n.phone.replace(/\s/g, '')},${n.extension}`" small link>
            <v-list-item-icon>
              <v-icon color='secondary'>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content class='phone'>
              <div><span v-if='!n.extension'>+48 </span>{{ n.phone }} <span v-if='n.extension'>wew. {{ n.extension
                }}</span></div>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    supplier: {
      type: Object,
      default: () => {
      }
    },
    closeDrawer: {
      type: Function,
      default: () => {
      }
    },

    mobile: {
      type: Boolean,
      default: false
    },

    links: {
      type: Array,
      default: () => []
    }
  },

  watch: {
    show(e) {
      if (!e) {
        this.$emit('drawer', false)
        this.show = true
      }
    }
  },

  data() {
    return {
      show: true,
      telephones: [{
        phone: '600 763 700',
        extension: '2',
        department: 'Dział rezerwacji'
      },
        {
          phone: '600 763 700',
          extension: '4, 1',
          department: 'Dział Produktu i sprawy techniczne dot. działania aplikacji'
        },
        {
          phone: '600 763 700',
          extension: '4, 3',
          department: 'Dział Płatności'
        }]
    }
  },

  computed: {}

}
</script>

<style lang='scss' scope>
.v-list-item__icon {
  margin: 13px 14px 0 0px !important;
}

.v-subheader.phone {
  font-size: 12px;
  height: 25px;
}
</style>
