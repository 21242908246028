import store from "@/store";
import * as Sentry from "@sentry/vue";
import {getCookie} from "@/helpers/cookies";
import {getConstant, isProduction} from "@/helpers/global";
import {getReasonPhrase} from './httpStatusCode';

const tokenName = getConstant('TOKEN_COOKIE_NAME');
const superAdminName = getConstant('SUPER_ADMIN_COOKIE_NAME');

const config = require('@/config');


class FetchData {
    constructor(as) {
        this.appservice = as;
        this.url = '';
        this.headers = {};
        this.requestOptions = {
            method: 'GET',
            headers: this.headers,
        };
    }

    clarity() {
        this.url = '';
        this.headers = {};
        this.requestOptions = {
            method: 'GET',
            headers: this.headers,
        };
    }

    setPath(path) {
        let api = this.appservice ? config.as_api : config.lm_api;

        if(!isProduction() && getCookie(superAdminName) === 'true') {
            api = config.super_admin;
        }

        if (path.charAt(0) === '/') {
            this.url = api + path;
        } else {
            this.url = path;
        }
    }

    setParams(params) {
        if (params) {
            this.url = this.url + '?' + params;
        }
    }

    setBody(body) {
        if (body) {
            this.requestOptions['body'] = body;
        }
    }

    removeBody() {
        if (this.requestOptions['body']) {
            delete this.requestOptions['body'];
        }
    }

    setToken() {
        if (getCookie(tokenName)) {
            this.headers["Authorization"] = `Bearer ${getCookie(tokenName)}`;
        }
    }

    setMethod(method) {
        this.requestOptions['method'] = method;
    }

    isJsonString(str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            Sentry.captureException(e);
            store.dispatch('app/showErrorMessage');
            return false;
        }
    }

    async refreshToken() {
        const url = this.url;
        const body = this.requestOptions.body;
        const method = this.requestOptions.method;

        if (await store.dispatch('auth/refreshToken')) {
            this.setToken();
            return await this.any(method, url, body)
        } else {
            await store.dispatch('auth/logout');
        }
    }

    isAuthenticated(response, code) {
        const unauthorized = ['INVALID_CREDENTIALS_CODE', 'INVALID_TOKEN_CODE', 'TOKEN_EXPIRED_CODE'];
        if(response.status === 401) {
            return false;
        }

        if(!code) {
            return true;
        }

        return !unauthorized.includes(getReasonPhrase(code));
    }

    async sendRequest() {
        Sentry.setExtra('body', this.requestOptions);

        try {
            const response = await fetch(this.url, this.requestOptions);
            const string = await response.text();
            const json = this.isJsonString(string);

            if (!json) {
                return {data: null};
            }

            const nonRedirectUrls = ["login", "ResetPassword", "token", "ResetPasswordByPinCode"];
            const matches = nonRedirectUrls.filter(s => this.url.includes(s));
            const hasMatches = matches.length > 0;
            let isAuthenticated = this.isAuthenticated(response, json.code);

            if (isAuthenticated || (hasMatches && !isAuthenticated)) {
                return { data: json };
            }

            if (localStorage.getItem('stayLoggedIn') === '1') {
                return await this.refreshToken();
            }

            await store.dispatch('auth/logout');

        } catch (e) {
            await store.dispatch('app/showErrorMessage', e);
            Sentry.captureException(e);
        }
    }

    any(method, path, body = null) {
        this.clarity();
        this.setMethod(method);
        this.setPath(path);
        this.setBody(body);
        this.setToken();
        return this.sendRequest();
    }

    delete(path, body = null) {
        this.clarity();
        this.setMethod('DELETE');
        this.setPath(path);
        this.setBody(body);
        this.setToken();
        return this.sendRequest();
    }

    put(path, body = null) {
        this.clarity();
        this.setMethod('PUT');
        this.setPath(path);
        this.setBody(body);
        this.setToken();
        return this.sendRequest();
    }

    path(path, body = null) {
        this.clarity();
        this.setMethod('PATCH');
        this.setPath(path);
        this.setBody(body);
        this.setToken();
        return this.sendRequest();
    }

    get(path, params = null) {
        this.clarity();
        this.setMethod('GET');
        this.setPath(path);
        this.setParams(params);
        this.removeBody();
        this.setToken();
        return this.sendRequest();
    }

    post(path, body = null, params = null) {
        this.clarity();
        this.setMethod('POST');
        this.setPath(path);
        this.setParams(params);
        this.setBody(body);
        this.setToken();
        return this.sendRequest();
    }
}

const fetchData = new FetchData(false);
const appserviceFetchData = new FetchData(true);

export {fetchData, appserviceFetchData};
